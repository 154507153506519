$screenSm: 767px;

.main-header {
  height: 45px;
  width: 100%;
  background-color: #BE252A;
  display: inline-flex;
  flex-direction: row;

  .select-language {
    background-color: #BE252A;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    position: relative;

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background: url("./../../shared/assets/images/icon/chevron_down.png");
      background-size: cover;
      right: 0;
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto 12px;
    }
  }

  .select-language-input {
    cursor: pointer;
    padding: 0 35px 0 20px;
    background-color: #BE252A;
    width: 100%;
    height: 100%;
  }

  .select-language-input.select-currency-input {
    background-color: #BE252A;
  }

  .select-currency {
    background-color: #BE252A;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;

    .logo {
      margin-left: 15px;
      width: 25px;
      height: 25px;
    }

    select {
      background-color: #BE252A;
      padding: 0 40px 0 0;

      option[value="php"] {
        background-image: url("../../shared/assets/images/logo/ph_logo.png");
        background-size: contain;
        background-position: left;
      }
    }

    &::before {
    }

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background: url("./../../shared/assets/images/icon/chevron_down.png");
      background-size: cover;
      right: 0;
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto 12px;
    }
  }

  .select-currency-dropdown {
    background-color: #BE252A;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 25px;
      height: 25px;
    }

    #dropdown-currency {
      display: flex;
      background-color: #BE252A;
      border: none;
      box-shadow: none;
      align-items: center;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        p {
          margin: 0;
          gap: 5px;
          font-size: 14px;
        }
      }
    }

    .dropdown-menu.show {
      background-color: #BE252A;

      .dropdown-item {
        .currency-select {
          display: flex;
          flex-direction: row;
          align-items: center;
          grid-gap: 5px;
          gap: 5px;
          color: #fff;

          p {
            margin: 0;
          }
        }

        &:hover {
          background-color: #ff6966;
        }
      }
    }
  }

  .follow-us {
    color: #fff;
    font-size: 14px;
    align-self: center;
  }

  .Dropdown-root.account-settings {
    margin-left: 20px;
    width: 150px;
  }

  .follow-icon {
    height: 45px;
    width: 38px;
    justify-content: center;
    align-items: center;
  }
}

/* Using SCSS variables to store breakpoints */

@media (max-width: $screenSm) {
  .main-header {
    .follow-us {
      display: none;
    }

    .Dropdown-root.account-settings {
      margin-left: 10px;

      .Dropdown-placeholder {
        display: none;
      }

      .Dropdown-arrow-wrapper {
        display: none;
      }

      .Dropdown-control {
        display: block;
        padding: 0;
        width: 50px;
      }

      .Dropdown-menu {
        left: -95px;
        width: 145px;
      }
    }

    .select-language-input {
      padding: 0 25px 0 10px;
      // width: 52px;

      &:hover {
        background-color: #ff6966;
      }
    }

    .select-language {
      &::after {
        margin: auto 7px;
      }
    }

    .select-currency-dropdown {
      #dropdown-currency {
        .currency-select {
          p {
            display: none;
          }
        }
      }
    }
  }
}
